var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Header', {
    attrs: {
      "filterConditions": _vm.filterConditions
    }
  }), _c('Content', {
    attrs: {
      "transactions": _vm.filteredTransactions,
      "brokersTransactions": _vm.filteredBrokersTransactions,
      "brokerTransactionsData": _vm.transactionsData,
      "loaded": _vm.loaded,
      "update-content": _vm.transactionUpdater
    },
    on: {
      "updateTransaction": _vm.updateTransaction,
      "getBrokerTransactions": _vm.getBrokersTransactions,
      "clearFilters": _vm.clearFilters
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }