<template>
  <div class="content" id="contact-content">
    <div v-if="!loaded">
      <div
          style="margin-top: 24px; position: absolute; top: 30%; left: 50%"
          class="loader"
      />
    </div>
    <div
        class="no-results-wrapper"
        v-else-if="(!transactions || (transactions.length === 0 && this.activeTransactionsTab === 'transactions')) || (!brokersTransactions || (brokersTransactions.length === 0 && this.activeTransactionsTab === 'brokers'))"
    >
      <p class="heading-geo32 no-results">
        {{ $t("NoResultsFound") }}
      </p>
      <p class="clear-filters-button" @click="$emit('clearFilters')">
        {{ $t("ClearAllFilters") }}
      </p>
    </div>

    <div v-else :key="'transactions-table-' + dataReloaded">
      <div style="width: max-content" v-if="activeTransactionsTab === 'transactions'">
        <div
            class="brokerage-fee-con"
            :style="'max-width: 1160px'"
        >
          <p class="font-16-regular">
            {{ $t("TotalBrokerageFee") }}: {{ brokerageFee }}
          </p>
        </div>

        <Table
            :fields="tableFields"
            :dataset="transactions"
            :add-gap="true"
            :transaction-view="true"
            :update-key="updatedTransactions"
            @transactionClicked="openTransactionSidepanel"
            @openStatusDropdown="openStatusDropDown"
            :sort-again="sortUpdater"
        />
      </div>
      <div style="width: max-content" v-else>
        <div style="height: 72px; border-radius: 8px; padding: 16px; display: flex; gap: 16px; background: white; margin-bottom: 12px">
          <div style="display: flex; justify-content: center; height: 40px; width: 100%; border-radius: 8px; padding: 8px 12px; background: #E8F7F8">
            <p class="font-16-regular">{{ $t('TotalTransactions')}}: <b>{{ $nMapper.price(brokerTransactionsData.totalBrokerageFee, "€") }}</b></p>
          </div>
          <div v-if="this.user.access.company._id !== '5p1EOo4yqjeiYCN3R16w'" style="display: flex; justify-content: center; height: 40px; width: 100%; border-radius: 8px; padding: 8px 12px; background: #FFF0F0">
            <p class="font-16-regular">{{ $t('NotPaid')}}: <b>{{ $nMapper.price(brokerTransactionsData.unpaidBrokerageFee, "€") }}</b></p>
          </div>
        </div>
        <Table
            :fields="brokerTableFields"
            :dataset="brokersTransactions"
            :add-gap="true"
            :transaction-view="true"
            :update-key="updateBrokerTransactions"
            @transactionClicked="openBrokerTransactionsSidepanel"
            :sort-again="brokerSortUpdater"
        />
      </div>
    </div>

    <transaction-broker-sidepanel
        v-if="sidepanelBroker"
        :broker="sidepanelBroker"
        @closePanel="sidepanelBroker = null"
        @transactionUpdated="$emit('getBrokerTransactions')"
    />

    <TransactionsDetail
        v-if="detailPanelOpen"
        @updateTransaction="
        (transaction) => $emit('updateTransaction', transaction)
      "
        @openStatusDropdown="openStatusDropDown"
        @closePanel="closeDetailPanel"
        :transactionId="selectedTransaction._id"
        :update-detail-panel="detailPanelUpdater"
    />
    <PaymentStatusDropdown
        v-if="statusOpen"
        :position="position"
        :transaction="selectedTransaction"
        @closed="statusOpen = false"
        @statusChanged="changeTransactionStatus"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Table from "@/components/common/Table";
import TransactionsDetail from "@/components/Transactions/TransactionsDetail";
import PaymentStatusDropdown from "./PaymentStatusDropdown";
import moment from "moment";
import TransactionBrokerSidepanel from "@/components/Transactions/TransactionBrokerSidepanel";

export default {
  name: "Content",
  props: ["transactions", "brokersTransactions", "brokerTransactionsData", "loaded", "updateContent"],
  components: {TransactionBrokerSidepanel, PaymentStatusDropdown, TransactionsDetail, Table},
  data() {
    return {
      detailPanelOpen: false,
      statusOpen: false,
      sidepanelBroker: null,
      position: {},
      dataReloaded: 0,
      updatedTransactions: 0,
      totalBrokerageFee: 0,
      detailPanelUpdater: 0,
      sortUpdater: 0,
      updateBrokerTransactions: 0,
      brokerSortUpdater: 0,
      brokerTableFields: [
        {
          fieldValue: this.$t('FirstName'),
          jsonValue: "firstName",
          style: "min-width:160px; max-width:160px; height:56px",
        },
        {
          fieldValue: this.$t("LastName"),
          jsonValue: "lastName",
          style: "min-width:160px; max-width:160px; height:56px",
        },
        {
          fieldValue: this.$t("Paid"),
          jsonValue: "paidBrokerageFee",
          style: "min-width:160px; max-width:160px; height:56px",
        },
        {
          fieldValue: this.$t("NotPaid"),
          jsonValue: "unpaidBrokerageFee",
          style: "min-width:160px; max-width:160px; height:56px",
        },
        {
          fieldValue: this.$t("Total"),
          jsonValue: "totalBrokerageFee",
          style: "min-width:160px; max-width:160px; height:56px",
        }
      ],
      tableFields: [
        {
          fieldValue: this.$t("ProjectName"),
          jsonValue: "transactionName",
          style: "min-width:288px; max-width:288px; height:56px",
        },
        {
          fieldValue: this.$t("Broker"),
          jsonValue: "creator",
          style: "min-width: 160px; max-width: 160px; height:56px",
        },
        {
          fieldValue: this.$t("DealValue"),
          jsonValue: "dealValue",
          style: "min-width: 160px; max-width: 160px; height:56px",
        },
        {
          fieldValue: this.$t("BrokerageFee"),
          jsonValue: "brokerageFee",
          style: "min-width: 160px; max-width: 160px; height:56px",
        },
        {
          fieldValue: this.$t("DateOfTransaction"),
          jsonValue: "transactionDate",
          style: "min-width: 160px; max-width: 160px; height:56px",
        },
        {
          fieldValue: this.$t("Created"),
          jsonValue: "createdAt",
          style: "min-width: 122px; max-width: 122px; height:56px",
        },
        {
          fieldValue: this.$t("Status"),
          jsonValue: "status",
          style: "min-width: 112px; max-width: 112px; height:56px",
        },
      ],
    };
  },
  created() {
    if (this.user.access.company._id === '5p1EOo4yqjeiYCN3R16w') {
      this.tableFields.pop()
      this.brokerTableFields.splice(2, 2)
    }
    if (this.$route.query.notificationId) {
      this.selectedTransaction = {_id: this.$route.query.notificationId}
      this.detailPanelOpen = true;
    }
  },
  mounted() {
    this.countTotalFees();
  },
  computed: {
    ...mapGetters(["user", "activeTransactionsTab"]),
    brokerageFee() {
      return this.$nMapper.price(this.totalBrokerageFee, "€");
    },
  },
  watch: {
    transactions() {
      this.updatedTransactions++;
      this.countTotalFees();
      this.sortUpdater++;
    },
    brokersTransactions() {
      this.updateBrokerTransactions++;
      this.brokerSortUpdater++;
    },
    updateContent() {
      this.detailPanelUpdater++;
      this.countTotalFees();
    },
    activeTransactionsTab() {
      this.dataReloaded++;
    },
    "$route.query.notificationId": function (transactionId) {
      if (transactionId) {
        this.selectedTransaction = {_id: transactionId}
        this.detailPanelOpen = true;
      }
    },
  },
  methods: {
    ...mapActions([]),
    countTotalFees() {
      this.totalBrokerageFee = 0;
      let tempTotalBrokerageFee = 0;
      this.transactions.forEach((transaction) => {
        if (transaction.brokerageFee) {
          tempTotalBrokerageFee += parseFloat(transaction.brokerageFee);
        }
      });
      const decimalsArray = tempTotalBrokerageFee.toString().split(".")
      if (decimalsArray[1] && decimalsArray[1].length >= 2) {
        tempTotalBrokerageFee = tempTotalBrokerageFee.toFixed(2);
      }

      this.totalBrokerageFee = tempTotalBrokerageFee;
    },
    closeDetailPanel() {
      this.detailPanelOpen = false;
      this.$router.push("/transactions");
    },
    openTransactionSidepanel(transaction) {
      if (this.statusOpen) return;
      this.detailPanelOpen = true;
      this.selectedTransaction = transaction;
    },
    openBrokerTransactionsSidepanel(broker) {
      this.sidepanelBroker = broker;
    },
    openStatusDropDown(transaction, position) {
      if (!this.hasAccess(transaction)) return;
      this.statusOpen = true;
      this.selectedTransaction = transaction;
      this.position = position;
    },
    async changeTransactionStatus(value) {
      if (this.selectedTransaction.status !== value) {
        this.selectedTransaction.status = value;
        this.selectedTransaction.paidDate = value === "paid" ? moment().format() : null
        await this.$emit("updateTransaction", this.selectedTransaction);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: block;
  padding: 24px 88px 72px 104px;
  min-height: 600px;
  background-color: #f4f5f7;
}

::v-deep .v-select__selections {
  flex-wrap: nowrap;
}

.no-results-wrapper {
  margin-top: 48px;
}

.brokerage-fee-con {
  margin-bottom: 12px;
  justify-content: end;
  display: flex;
}
</style>
