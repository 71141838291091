var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content",
    attrs: {
      "id": "contact-content"
    }
  }, [!_vm.loaded ? _c('div', [_c('div', {
    staticClass: "loader",
    staticStyle: {
      "margin-top": "24px",
      "position": "absolute",
      "top": "30%",
      "left": "50%"
    }
  })]) : !_vm.transactions || _vm.transactions.length === 0 && this.activeTransactionsTab === 'transactions' || !_vm.brokersTransactions || _vm.brokersTransactions.length === 0 && this.activeTransactionsTab === 'brokers' ? _c('div', {
    staticClass: "no-results-wrapper"
  }, [_c('p', {
    staticClass: "heading-geo32 no-results"
  }, [_vm._v(" " + _vm._s(_vm.$t("NoResultsFound")) + " ")]), _c('p', {
    staticClass: "clear-filters-button",
    on: {
      "click": function ($event) {
        return _vm.$emit('clearFilters');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ClearAllFilters")) + " ")])]) : _c('div', {
    key: 'transactions-table-' + _vm.dataReloaded
  }, [_vm.activeTransactionsTab === 'transactions' ? _c('div', {
    staticStyle: {
      "width": "max-content"
    }
  }, [_c('div', {
    staticClass: "brokerage-fee-con",
    style: 'max-width: 1160px'
  }, [_c('p', {
    staticClass: "font-16-regular"
  }, [_vm._v(" " + _vm._s(_vm.$t("TotalBrokerageFee")) + ": " + _vm._s(_vm.brokerageFee) + " ")])]), _c('Table', {
    attrs: {
      "fields": _vm.tableFields,
      "dataset": _vm.transactions,
      "add-gap": true,
      "transaction-view": true,
      "update-key": _vm.updatedTransactions,
      "sort-again": _vm.sortUpdater
    },
    on: {
      "transactionClicked": _vm.openTransactionSidepanel,
      "openStatusDropdown": _vm.openStatusDropDown
    }
  })], 1) : _c('div', {
    staticStyle: {
      "width": "max-content"
    }
  }, [_c('div', {
    staticStyle: {
      "height": "72px",
      "border-radius": "8px",
      "padding": "16px",
      "display": "flex",
      "gap": "16px",
      "background": "white",
      "margin-bottom": "12px"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "center",
      "height": "40px",
      "width": "100%",
      "border-radius": "8px",
      "padding": "8px 12px",
      "background": "#E8F7F8"
    }
  }, [_c('p', {
    staticClass: "font-16-regular"
  }, [_vm._v(_vm._s(_vm.$t('TotalTransactions')) + ": "), _c('b', [_vm._v(_vm._s(_vm.$nMapper.price(_vm.brokerTransactionsData.totalBrokerageFee, "€")))])])]), this.user.access.company._id !== '5p1EOo4yqjeiYCN3R16w' ? _c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "center",
      "height": "40px",
      "width": "100%",
      "border-radius": "8px",
      "padding": "8px 12px",
      "background": "#FFF0F0"
    }
  }, [_c('p', {
    staticClass: "font-16-regular"
  }, [_vm._v(_vm._s(_vm.$t('NotPaid')) + ": "), _c('b', [_vm._v(_vm._s(_vm.$nMapper.price(_vm.brokerTransactionsData.unpaidBrokerageFee, "€")))])])]) : _vm._e()]), _c('Table', {
    attrs: {
      "fields": _vm.brokerTableFields,
      "dataset": _vm.brokersTransactions,
      "add-gap": true,
      "transaction-view": true,
      "update-key": _vm.updateBrokerTransactions,
      "sort-again": _vm.brokerSortUpdater
    },
    on: {
      "transactionClicked": _vm.openBrokerTransactionsSidepanel
    }
  })], 1)]), _vm.sidepanelBroker ? _c('transaction-broker-sidepanel', {
    attrs: {
      "broker": _vm.sidepanelBroker
    },
    on: {
      "closePanel": function ($event) {
        _vm.sidepanelBroker = null;
      },
      "transactionUpdated": function ($event) {
        return _vm.$emit('getBrokerTransactions');
      }
    }
  }) : _vm._e(), _vm.detailPanelOpen ? _c('TransactionsDetail', {
    attrs: {
      "transactionId": _vm.selectedTransaction._id,
      "update-detail-panel": _vm.detailPanelUpdater
    },
    on: {
      "updateTransaction": function (transaction) {
        return _vm.$emit('updateTransaction', transaction);
      },
      "openStatusDropdown": _vm.openStatusDropDown,
      "closePanel": _vm.closeDetailPanel
    }
  }) : _vm._e(), _vm.statusOpen ? _c('PaymentStatusDropdown', {
    attrs: {
      "position": _vm.position,
      "transaction": _vm.selectedTransaction
    },
    on: {
      "closed": function ($event) {
        _vm.statusOpen = false;
      },
      "statusChanged": _vm.changeTransactionStatus
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }