var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "transaction-header"
  }, [_c('div', {
    staticClass: "transaction-header-title",
    staticStyle: {
      "padding-left": "104px"
    }
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t("Transactions")))])]), _c('div', {
    staticStyle: {
      "padding-left": "104px",
      "margin-top": "0",
      "display": "flex"
    },
    attrs: {
      "id": "TABS"
    }
  }, [_c('div', {
    class: _vm.activeTab === 'transactions' ? 'tab-selected content-small' : 'tab-unselected content-small',
    on: {
      "click": function ($event) {
        return _vm.changeActiveTab('transactions');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Transactions")) + " ")]), _vm.showBrokersTab ? _c('div', {
    class: _vm.activeTab === 'brokers' ? 'tab-selected content-small' : 'tab-unselected content-small',
    staticStyle: {
      "margin-left": "24px"
    },
    on: {
      "click": function ($event) {
        return _vm.changeActiveTab('brokers');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Brokers")) + " ")]) : _vm._e()]), _c('HeaderToolbar', {
    attrs: {
      "module": 'transactions'
    }
  }, [_vm.activeTab === 'transactions' ? [_c('div', {
    key: 'header-' + _vm.headerUpdater
  }, [_c('FilterDropdown', {
    attrs: {
      "filter-name": "transactions"
    }
  })], 1)] : [_c('div', {
    key: 'header2-' + _vm.headerUpdater
  }, [_c('FilterDropdown', {
    attrs: {
      "filter-name": "brokerTransactions"
    }
  })], 1)], _c('img', {
    staticClass: "point-separator",
    attrs: {
      "src": require("../../assets/images/•.svg"),
      "alt": ""
    }
  }), _c('v-text-field', {
    staticStyle: {
      "background": "white",
      "max-width": "240px"
    },
    attrs: {
      "autocomplete": "off",
      "outlined": "",
      "hide-details": "",
      "id": 'search-bar',
      "prepend-inner-icon": "mdi-magnify",
      "placeholder": _vm.$t('Search') + '...'
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _c('GroupOrUserSelect', {
    attrs: {
      "module": "transactions"
    },
    model: {
      value: _vm.chosenUsers,
      callback: function ($$v) {
        _vm.chosenUsers = $$v;
      },
      expression: "chosenUsers"
    }
  }), _c('div', {
    staticStyle: {
      "width": "172px'"
    }
  }, [_c('DropdownMulti', {
    attrs: {
      "items": _vm.projectTypes,
      "display-key": 'name',
      "value-key": 'value',
      "width": '172px',
      "height": '34px',
      "font-size": '12px',
      "default-value": _vm.$t('AllProjectTypes')
    },
    model: {
      value: _vm.selectedProjects,
      callback: function ($$v) {
        _vm.selectedProjects = $$v;
      },
      expression: "selectedProjects"
    }
  })], 1), _vm.user.access.company._id !== '5p1EOo4yqjeiYCN3R16w' ? _c('TransactionStatus', {
    attrs: {
      "status": _vm.status
    },
    on: {
      "selectStatus": _vm.filterByStatus
    }
  }) : _vm._e(), _c('p', {
    staticClass: "label12"
  }, [_vm._v(_vm._s(_vm.$t("TransactionDate") + ':'))]), _vm.pastDayOptions ? _c('Dropdown', {
    attrs: {
      "items": _vm.pastDayOptions,
      "display-key": 'name',
      "value-key": 'name',
      "default-item": _vm.pastDayOptions[4],
      "selected": _vm.transactionDateFilter
    },
    on: {
      "itemSelected": _vm.filterByTransactionDate
    }
  }) : _vm._e()], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }