<template>
  <div class="transaction-header">
    <div class="transaction-header-title" style="padding-left: 104px">
      <h3>{{ $t("Transactions") }}</h3>
    </div>

    <div id="TABS" style="padding-left: 104px; margin-top: 0; display: flex;">
      <div
          @click="changeActiveTab('transactions')"
          :class="
          activeTab === 'transactions'
            ? 'tab-selected content-small'
            : 'tab-unselected content-small'
        "
      >
        {{ $t("Transactions") }}
      </div>
      <div
        v-if="showBrokersTab"
        @click="changeActiveTab('brokers')"
        :class="activeTab === 'brokers'
          ? 'tab-selected content-small'
          : 'tab-unselected content-small'
        "
        style="margin-left: 24px"
      >
        {{ $t("Brokers") }}
      </div>
    </div>

    <HeaderToolbar
        :module="'transactions'"
    >

      <template v-if="activeTab === 'transactions'">
        <div :key="'header-' + headerUpdater">
          <FilterDropdown
              filter-name="transactions"
          />
        </div>
      </template>
      <template v-else>
        <div :key="'header2-' + headerUpdater">
          <FilterDropdown
              filter-name="brokerTransactions"
          />
        </div>
      </template>

      <img class="point-separator" src="../../assets/images/•.svg" alt=""/>

      <v-text-field
          autocomplete="off"
          outlined
          v-model="search"
          hide-details
          :id="'search-bar'"
          prepend-inner-icon="mdi-magnify"
          :placeholder="$t('Search') + '...'"
          style="background: white; max-width: 240px"
      />
      <GroupOrUserSelect v-model="chosenUsers" module="transactions"/>
      <div style="width: 172px'">
        <DropdownMulti
            :items="projectTypes"
            v-model="selectedProjects"
            :display-key="'name'"
            :value-key="'value'"
            :width="'172px'"
            :height="'34px'"
            :font-size="'12px'"
            :default-value="$t('AllProjectTypes')"
        />
      </div>

      <TransactionStatus
          :status="status"
          @selectStatus="filterByStatus"
          v-if="user.access.company._id !== '5p1EOo4yqjeiYCN3R16w'"
      />
      <p class="label12">{{$t("TransactionDate") + ':'}}</p>
      <Dropdown
        v-if="pastDayOptions"
        :items="pastDayOptions"
        :display-key="'name'"
        :value-key="'name'"
        :default-item="pastDayOptions[4]"
        :selected="transactionDateFilter"
        @itemSelected="filterByTransactionDate"
      />
    </HeaderToolbar>
  </div>
</template>

<script>
import HeaderToolbar from "@/components/common/HeaderToolbar";
import DropdownMulti from "@/components/common/DropdownMulti";
import FilterDropdown from "@/components/common/FilterDropdown";
import {mapActions, mapGetters} from "vuex";
import TransactionStatus from "@/components/Transactions/TransactionStatus";
//import moment from "moment";
import GroupOrUserSelect from "@/components/FormElements/GroupOrUserSelect";
import Dropdown from "@/components/common/Dropdown";


export default {
  name: "TransactionHeader",
  props: ["filterConditions"],
  components: {
    TransactionStatus,
    HeaderToolbar,
    DropdownMulti,
    FilterDropdown,
    GroupOrUserSelect,
    Dropdown
  },
  data() {
    return {
      chosenUsers: null,
      activeTab: "",
      headerUpdater: 0,
      search: "",
      selectedUser: null,
      dealTypes: [],
      status: "",
      transactionDateFilter: {},
      projectTypeDropownOpen: false,
      colleagueUpdater: 0,
      chosenBrokerId: null,
      selectedProjectTypes: null,
      filtersUpdater: 0,
      colleagues: [
        {name: this.$t("AllBrokers"), value: "allBrokers", _id: "allBrokers"},
      ],
      selectedProjects: [],
      preSelectedItems: [],
      projectTypes: [
        {name: this.$t("Sale"), value: "Sale"},
        {name: this.$t("LeaseOut"), value: "LeaseOut"},
        {name: this.$t("Purchase"), value: "Purchase"},
        {name: this.$t("AquireLease"), value: "AquireLease"},
        {name: this.$t("Referral"), value: "Refferal"},
      ],
      dropdownPreselectedUpdated: false,
    };
  },
  created() {
    this.getActiveTab();
    this.getUsers();
    this.getGroups();
  },
  mounted() {
    this.filterByConditions();
  },
  watch: {
    chosenUsers: async function (value) {
      let filterName = this.activeTab === 'brokers' ? "brokerTransactions" : "transactions";
      await this.changeProvisionalFilterCondition({
        filterName: filterName,
        conditionName: "brokerId",
        value: value,
        valueTitle: '',
      });
    },
    search: async function (val) {
      let filterName = this.activeTab === 'brokers' ? "brokerTransactions" : "transactions";
      await this.changeProvisionalFilterCondition({
        filterName: filterName,
        conditionName: "search",
        value: val,
      });
    },
    filterConditions() {
      this.filterByConditions();
    },
    activeTransactionsTab() {
      this.headerUpdater++;
      this.colleagueUpdater++;
      this.dropdownPreselectedUpdated = false;
    },
    selectedProjects: {
      handler(newVal) {
        let projectsArray = [];
        let filterName = this.activeTab === 'brokers' ? "brokerTransactions" : "transactions";
        newVal.forEach((project) => {
          projectsArray.push(project.value);
        });
        this.changeProvisionalFilterCondition({
          filterName: filterName,
          conditionName: "dealTypes",
          value: projectsArray,
          valueTitle: projectsArray.join(", "),
        });
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters([
      "user",
      "users",
      "activeTransactionsTab",
      "provisionalFiltersUpdater",
      "groups"
    ]), 
    showBrokersTab() {

      return this.user.access.accessLevel === 'group_admin'
        || this.user.access.accessLevel === 'company_admin'
        || this.user.companyPolicies?.filters?.transactions?.showAll
    },
    pastDayOptions() {
      return [
        {
          name: this.$t("Today"),
          value: "today",
        },
        {
          name: this.$t("Yesterday"),
          value: "yesterday",
        },
        {
          name: this.$t("InLast") + " 3 " + this.$t("InLastDays"),
          value: "last_3_days",
        },
        {
          name: this.$t("InLast") + " 14 " + this.$t("InLastDays"),
          value: "last_14_days",
        },
        {
          name: this.$t("ThisMonth"),
          value: "this_month",
        },
        {
          name: this.$t("LastMonth"),
          value: "last_month",
        },
        {
          name: this.$t("InLast") + " 3 " + this.$t("InLastMonths"),
          value: "last_3_months",
        },
        {
          name: this.$t("CurrentYear"),
          value: "current_year",
        },
        {
          name: this.$t("InLast") + " 12 " + this.$t("InLastMonths"),
          value: "last_year",
        },
      ];
    },
  },
  methods: {
    ...mapActions([
      "getUsers",
      "setProvisionalFilter",
      "changeProvisionalFilterCondition",
      "setActiveTransactionsTab",
      "getGroups"
    ]),
    async getActiveTab() {
      // get activeTab from state, if activeTab not in state, add activeTab to state with value of transactions
      if (this.activeTransactionsTab) {
        this.activeTab = this.activeTransactionsTab;
      } else {
        this.activeTab = 'transactions';
        await this.setActiveTransactionsTab("transactions")
      }
    },
    async changeActiveTab(value) {
      await this.setActiveTransactionsTab(value);
      this.activeTab = value;
    },
    filterByConditions() {
      this.filterConditions.forEach((condition) => {
        switch (condition.name) {
          case "search":
            this.search = condition.value;
            break;
          case "dealTypes":
            console.log(JSON.parse(JSON.stringify(this.projectTypes)))
            this.preSelectedItems = [];
            this.projectTypes.forEach((type) => {
              type.selected = condition.value.length === 0
                  ? false
                  : condition.value.includes(type.value);
              if (type.selected) this.preSelectedItems.push(type);
            });
            break;
          case "brokerId":
            if (condition.value) {
              if (!condition.value.type || (condition.value.type && !condition.value.value)) {
                condition.value = null;
              }
            } else {
              condition.value = null;
            }
            this.chosenUsers = condition.value
            break;
          case "status":
            this.status = condition.value;
            break;
          case "transactionDate":
            if (condition.value) {
              this.transactionDateFilter = this.pastDayOptions.find((item) => item.value === condition.value)
            } else {
              this.transactionDateFilter = this.pastDayOptions[4]
            }
            break;
        }
      });
      if (!this.dropdownPreselectedUpdated) {
        this.colleagueUpdater++;
      }
      this.dropdownPreselectedUpdated = true;
    },
    async filterByProjectType(projectTypes) {
      let filterName = this.activeTab === 'brokers' ? "brokerTransactions" : "transactions";
      let projectArray = [];
      projectTypes.forEach((project) => {
        projectArray.push(project.value);
      });
      await this.changeProvisionalFilterCondition({
        filterName: filterName,
        conditionName: "dealTypes",
        value: projectArray,
        valueTitle: projectArray.join(", "),
      });
    },
    async filterByStatus(val) {
      let filterName = this.activeTab === 'brokers' ? "brokerTransactions" : "transactions";
      await this.changeProvisionalFilterCondition({
        filterName: filterName,
        conditionName: "status",
        value: val,
        valueTitle: val,
      });
    },
    async filterByTransactionDate(newTimeFrame) {
      let filterName = this.activeTab === 'brokers' ? "brokerTransactions" : "transactions";
      await this.changeProvisionalFilterCondition({
        filterName: filterName,
        conditionName: "transactionDate",
        value: newTimeFrame.value,
        valueTitle: newTimeFrame.name,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-selected {
  padding-bottom: 24px;
  font-weight: 600;
  border-bottom: 2px solid black;
  cursor: pointer;
}

.tab-unselected {
  padding-bottom: 24px;
  cursor: pointer;
}

.transaction-header {
  background: #fff;
}

.transaction-from-to-filter {
  display: flex;
  gap: 8px;
}

.transaction-header-title {
  height: 72px;
  padding: 16px 48px 24px 48px;
}

::v-deep .v-text-field .v-input__control .v-input__slot {
  min-height: 34px !important;
  max-height: 34px !important;
}

::v-deep .v-text-field--enclosed .v-input__prepend-inner {
  margin-top: 5.5px;
}

::v-deep .v-icon.v-icon {
  font-size: 18px;
}

::v-deep .theme--light.v-icon {
  color: black;
}

::v-deep .v-text-field__slot input {
  font: revert !important;
}

</style>
