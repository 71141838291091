<template>
  <div>
    <Header :filterConditions="filterConditions"/>
    <Content
        :transactions="filteredTransactions"
        :brokersTransactions="filteredBrokersTransactions"
        :brokerTransactionsData="transactionsData"
        :loaded="loaded"
        @updateTransaction="updateTransaction"
        @getBrokerTransactions="getBrokersTransactions"
        @clearFilters="clearFilters"
        :update-content="transactionUpdater"
    />
  </div>
</template>

<script>
import Header from "../../components/Transactions/Header.vue";
import Content from "../../components/Transactions/Content.vue";
import axios from "axios";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Transactions",
  components: {
    Header,
    Content,
  },
  data() {
    return {
      allTransactions: [],
      filteredTransactions: [],
      filteredBrokersTransactions: [],
      transactionsData: {},
      filterConditions: [],
      loaded: false,
      transactionUpdater: 0,
    };
  },
  async created() {
    await this.initializeFilters();
  },
  mounted() {
  },
  computed: {
    ...mapGetters([
      "provisionalFiltersUpdater",
      "provisionalFilters",
      "filters",
      "user",
      "users",
      "activeTransactionsTab"
    ]),
  },
  methods: {
    ...mapActions([
      "setProvisionalFilter",
      "setActiveTransactionsTab"
    ]),
    async initializeFilters() {
      const savedViewId = this.$route.params.savedViewId;
      const activeTab = this.$route.params.activeTab;
      let brokersInitFilterCondition;
      let initFilterCondition;
     /*  this.filters["transactions"].find(
          (item) => item._id === '4'
      ).conditions.find((condition) => condition.name === 'tag').value = 'this_month'; */
      if (!this.filters['transactions'][0].conditions.find((item) => item.title === 'TransactionDate')) {
        this.filters['transactions'][0].conditions.push({
          name: "transactionDate",
          title: "TransactionDate",
          value: "this_month",
          valueTitle: "",
        })
      }
      if (!this.filters['brokerTransactions'][0].conditions.find((item) => item.title === 'TransactionDate')) {
        this.filters['brokerTransactions'][0].conditions.push({
          name: "transactionDate",
          title: "TransactionDate",
          value: "this_month",
          valueTitle: "",
        })
      }
      this.filters["transactions"].find(
          (item) => item.filterTitle === 'AllTransactions'
      ).conditions.find((condition) => condition.name === 'transactionDate').value = 'this_month';
      if (activeTab && savedViewId) {
        this.setActiveTransactionsTab(activeTab);
        if (activeTab === "transactions") {
          initFilterCondition = this.filters["transactions"].find(
              (item) => item._id === savedViewId
          ).conditions
          let brokersFilter = this.filters["brokerTransactions"].find(
              (item) => item.isDefault
          )
          brokersInitFilterCondition = brokersFilter.conditions
          const brokerField = brokersInitFilterCondition.find((condition) => condition.name === 'brokerId')

          if (this.user.access.accessLevel === "company_admin") {
            if (brokersFilter.name === "allValues") {
              brokerField.value = null;
              brokersFilter.filterTitle = "AllTransactions";
            }
          } else {
            if (brokersFilter.name === "allValues") {
              brokerField.value = {
                type: "user",
                value: this.user._id
              }
              brokersFilter.filterTitle = "MyTransactions";
            }
          }
        } else {
          brokersInitFilterCondition = this.filters["brokerTransactions"].find(
              (item) => item._id === savedViewId
          ).conditions
          let initFilter = this.filters["transactions"].find(
              (item) => item.isDefault
          )
          initFilterCondition = initFilter.conditions
          const brokerField = initFilterCondition.find((condition) => condition.name === 'brokerId')
          if (this.user.access.accessLevel === "company_admin") {
            if (initFilter.name === "allValues") {
              brokerField.value = null;
              initFilter.filterTitle = "AllTransactions";
            }
          } else {
            if (initFilter.name === "allValues") {
              brokerField.value = {
                type: "user",
                value: this.user._id
              }
              initFilter.filterTitle = "MyTransactions";
            }
          }

        }
      } else {
        const brokerFilter = this.filters["brokerTransactions"].find(
            (item) => item.isDefault
        )
        brokersInitFilterCondition = brokerFilter.conditions
        const brokerField = brokersInitFilterCondition.find((condition) => condition.name === 'brokerId');
        const initFilter = this.filters["transactions"].find(
            (item) => item.isDefault
        )
        initFilterCondition = initFilter.conditions
        const brokerField2 = initFilterCondition.find((condition) => condition.name === 'brokerId');

        if (this.user.access.accessLevel === "company_admin") {
          if (brokerFilter.name === "allValues") {
            brokerField.value = null;
            brokerFilter.filterTitle = "AllTransactions";
          }
          if (initFilter.name === "allValues") {
            brokerField2.value = null;
            initFilter.filterTitle = "AllTransactions";
          }
        } else {
          if (brokerFilter.name === "allValues") {
            brokerField.value = {
              type: "user",
              value: this.user._id
            }
            brokerFilter.filterTitle = "MyTransactions";
          }
          if (initFilter.name === "allValues") {
            brokerField2.value = {
              type: "user",
              value: this.user._id
            }
            initFilter.filterTitle = "MyTransactions";
          }
        }

      }
      if (!brokersInitFilterCondition) {
        brokersInitFilterCondition = this.filters["brokerTransactions"][0].conditions
      }
      if (!initFilterCondition) {
        initFilterCondition = this.filters["transactions"][0].conditions
      }
      if (this.activeTransactionsTab === 'brokers') {
        this.filterConditions = brokersInitFilterCondition;
      } else {
        this.filterConditions = initFilterCondition;
      }

      await this.setProvisionalFilter({
        filterName: "brokerTransactions",
        conditions: brokersInitFilterCondition,
      });
      await this.setProvisionalFilter({
        filterName: "transactions",
        conditions: initFilterCondition,
      });
    },
    async clearFilters() {
      let filterName = this.activeTransactionsTab === 'brokers' ? 'brokerTransactions' : 'transactions';
      let conditions = this.filters[filterName].find(
          (item) => item.isDefault
      ).conditions
      if (!conditions) {
        conditions = this.filters[filterName][0].conditions;
      }
      await this.setProvisionalFilter({
        filterName: filterName,
        conditions: conditions,
      });
    },
    async getAllTransactions() {
      const response = await axios.post("/api/transactions/filtered", this.provisionalFilters["transactions"]);
      this.filterConditions = this.provisionalFilters["transactions"];
      this.filteredTransactions = response.data;
    },
    async getBrokersTransactions() {
      const response = await axios.post("/api/transactions/brokers", this.provisionalFilters["brokerTransactions"]);
      this.filterConditions = this.provisionalFilters["brokerTransactions"]
      this.transactionsData.totalBrokerageFee = response.data?.totalBrokerageFee;
      this.transactionsData.unpaidBrokerageFee = response.data?.unpaidBrokerageFee;
      this.filteredBrokersTransactions = response.data?.tableData;
    },
    async updateTransaction(updatedTransaction) {
      let response = await axios.patch(
          `/api/transaction-update/${updatedTransaction._id}`,
          updatedTransaction
      );

      if (response.status === 200) {
        this.toastSuccess(this.$t("TransactionUpdated"));
        this.allTransactions = this.allTransactions.map(transaction => transaction._id !== updatedTransaction._id
            ? transaction
            : updatedTransaction);
        this.filteredTransactions = this.filteredTransactions.map(transaction => transaction._id !== updatedTransaction._id
            ? transaction
            : updatedTransaction);
        this.transactionUpdater++
        await this.getAllTransactions();
      } else {
        this.toastError(this.$t("ErrorUpdating"));
      }
    },
  },
  watch: {
    async provisionalFiltersUpdater() {
      this.loaded = false;
      if (this.activeTransactionsTab && this.activeTransactionsTab === 'brokers') {
        await this.getBrokersTransactions()
      } else {
        await this.getAllTransactions()
      }
      this.loaded = true;
    },
    async activeTransactionsTab(value) {
      this.loaded = false;
      if (value && value === 'brokers') {
        await this.getBrokersTransactions()
      } else {
        await this.getAllTransactions()
      }
      this.loaded = true;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
